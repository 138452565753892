// components/ImageComponent.js

import React, { useState, useEffect } from 'react';
import nouser from '../../assets/nouser.png'; // Another default image
import { checkImageExists } from '../../utilityFunctions/utilsFunctions';
import envValues from '../../enviornment';
// eslint-disable-next-line react/prop-types
function ImageComponent({ src = '', alt = '', cssStyle = {} }) {
  const defaultImage = nouser;
  const [imageUrl, setImageUrl] = useState(defaultImage);
  useEffect(() => {
    const fullimageurl = `${envValues.S3_PATH}${src}`;
    const fetchImage = async () => {
      const imageExists = await checkImageExists(fullimageurl);
      if (imageExists) {
        setImageUrl(fullimageurl);
      } else {
        setImageUrl(defaultImage);
      }
    };

    fetchImage();
  }, [src, defaultImage]);

  return <img src={imageUrl} alt={alt} style={cssStyle} />;
}

export default ImageComponent;
